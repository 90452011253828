<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" >
  <div class="container">
    <a routerLink="/about-us"  class="texto-blanco" (click)=" closeMenu(); closeSubMenu()">
    <img src="/assets/img/demo-img/AltafoxLogo.svg.jpg" height="80" alt="Logo">
    </a>
    <button class="navbar-toggler" type="button" (click)="toggleClass()">
      <span class="burger-menu">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
      </span>
    </button>
    <div class="collapse navbar-collapse " style="margin-left:5em" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto" style="margin-top:2em">
        <li class="nav-item" [class.active]="currentSection === 'about-us'">
          <div>
            <a [routerLink]="['/about-us']" [queryParams]="{ section: 'about-us' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section3'); closeMenu()">SOBRE NOSOTROS
            </a>
          </div>
        </li>
        <li class="nav-item" [class.active]="currentSection === 'byd-batterybox'">
          <div>
            <a routerLink="/byd-batterybox" [queryParams]="{ section: 'byd-batterybox' }" class="link-btn texto-blanco texto-secciones" (click)=" changeSection('byd-batterybox'); closeMenu(); closeSubMenu()">BYD BATTERY-BOX</a>
          </div>
        </li>
        
        <li class="nav-item" [class.active]="currentSection === 'solutions'">
          <div>
            <a routerLink="/solutions" [queryParams]="{ section: 'solutions' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section2'); closeMenu(); closeSubMenu()">C&I SOLUTIONS</a>
          </div>
        </li>
        <li class="nav-item" [class.active]="currentSection === 'Where-to-buy'">
          <div>
            <a [routerLink]="['/Where-to-buy']" [queryParams]="{ section: 'Where-to-buy' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section4'); closeMenu()">DÓNDE COMPRAR
            </a>
          </div>
        </li>
        <li class="nav-item" [class.active]="currentSection === 'Contact'">
          <div>
            <a [routerLink]="['/Contact']" [queryParams]="{ section: 'Contact' }" class="link-btn texto-blanco texto-secciones" (click)="changeSection('section5'); closeMenu()">CONTACTO</a>
          </div>
        </li>
      </ul>
      <!-- <ul class="navbar-nav ml-auto">
        <li class="nav-item" [class.active]="currentSection === 'Online-service-center'">
          <div>
            <a href="https://support.collective-energy.africa/" target="_blank" class="link-btn texto-secciones online-margin" (click)="changeSection('section6'); closeMenu()" style="margin-right: 1em;">
              <span style="color:#3A9B43">Online service center</span>
            </a>
          </div>
        </li>
        <li class="nav-item text-right">
          <div>
            <button class="btn btn-primary" style="margin-top: 2.5em; margin-right: 1.5em;">
              <a [routerLink]="['/Downloads']" [queryParams]="{ section: 'Downloads' }" (click)="closeMenu()">
                <span class="texto-blanco texto-secciones" style="border-bottom: transparent !important;">Downloads</span>
              </a>
            </button>
          </div>
        </li>
      </ul> -->
      <ul class="navbar-nav ml-left for-responsive">
        <li class="nav-item"><span class="nav-link" routerLink="/byd-batterybox" (click)="closeMenu()">BYD BatteryBox</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/solutions" (click)="closeMenu()">C&I Solutions</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/about-us" (click)="closeMenu()">SOBRE NOSOTROS</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/Where-to-buy" (click)="closeMenu()">DÓNDE COMPRAR</span></li>
        <li class="nav-item"><span class="nav-link" routerLink="/Contact" (click)="closeMenu()">CONTACTO</span></li>
      </ul>
    </div>
  </div>
</nav>
