import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  currentSection: string = ''; // Inicialmente no hay ninguna sección seleccionada
  showBatteryBoxContent: boolean = true;


  // Método para cambiar la clase de la sección actual
  changeSection(section: string) {
    this.currentSection = section;
    console.log(this.currentSection)
  }

    constructor(private viewportScroller: ViewportScroller,private route: ActivatedRoute, private router: Router) {}

    toggleBatteryBoxContent() {
      this.showBatteryBoxContent = !this.showBatteryBoxContent;
    }
  
    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
      this.route.queryParamMap.subscribe(queryParams => {
        this.currentSection = queryParams.get('section') || ''; // If no section query parameter, set it to an empty string
        this.highlightActiveSection(this.currentSection);
      });
    }
    highlightActiveSection(sectionId: string): void {
      const navLinks = document.querySelectorAll('.navbar-nav .link-btn');
      navLinks.forEach(link => {
        link.classList.remove('active');
        const routerLink = link.getAttribute('href');
        if (routerLink && routerLink === `/${sectionId}`) {
          link.classList.add('active');
        }
      });
    }

    isText1Visible: boolean = false;
    isText2Visible: boolean = false;
    isText3Visible: boolean = false;
  
    hideText1(): void {
      this.isText1Visible = false;
    }
  
    hideText2(): void {
      this.isText2Visible = false;
    }
  
    hideText3(): void {
      this.isText3Visible = false;
    }
  
    showText1(): void {
      this.isText1Visible = true;
    }
  
    showText2(): void {
      this.isText2Visible = true;
    }
  
    showText3(): void {
      this.isText3Visible = true;
    }
        

}