<div class=" bg-0B1F0D  pt-100 pb-70" >
    <div class="container">
        <div class="section">
          <br><br>
            <div class="battery-cea-home">
                <span style="color:#EFE6C0; opacity:0.3" >BYD <br> BATTERYBOX</span><br><br>
            </div> 
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="container-img container-img-resp-2 text-center">
                <img src="assets/img/LVL Frontal.png" style="width: auto; height: auto;padding-top:5em;" alt="services-img"> 
            </div>
            <div class="services-content text-center">
                <h3 class="texto-blanco fuente-cool-light">BYD Premium LVL</h3>
                <ul>
                    <li class="mt-2">
                      <a href="assets/pdf/Premium-LVL-Datasheet.PDF" target="_blank" class="text-crema">DATASHEET</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/Premium_LVL_Compatibility_List.pdf" target="_blank" class="text-crema">COMPATIBILITY LIST</a>
                    </li >
                    <li class="mt-2">
                      <a href="assets/pdf/Premium Quick Start Guide LVL.pdf" target="_blank" class="text-crema">QUICK START GUIDE</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/Premium Operating Manual LVL.pdf" target="_blank" class="text-crema">OPERATION MANUAL</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/Premium_LVL_Service_Guideline_and_Checklist-.pdf" target="_blank" class="text-crema">SERVICE GUIDELINE AND CHECKLIST</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/Premium LVL Limited Warranty-Commercial.pdf" target="_blank" class="text-crema">LIMITED WARRANTY</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/Battery-Box LVL FAQs.pdf" target="_blank" class="text-crema">FAQS</a>
                    </li>
                  </ul>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="container-img container-img-resp text-center">
              <img src="assets/img/LV FLEX LITE.png"  style="width: auto; height: 80%; padding-top:3em" alt="services-img">
            </div>
            <div class="services-content text-center">
              <h3 class="texto-blanco fuente-cool-light">BYD LV Flex Lite</h3>
              <ul class="text-center">
                <li class="mt-2">
                  <a href="assets/pdf/LV Flex Lite Datasheet.PDF" target="_blank" class="text-crema">DATASHEET</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/230301_BYD Battery-Box LV Flex Lite Minimum Configuration List V1.2-63fef38aca9b7.pdf" target="_blank" class="text-crema">COMPATIBILITY LIST</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/Battery-Box LV Flex Lite Quick Start Guide V1.1-61640d6c4800d.pdf" target="_blank" class="text-crema">QUICK START GUIDE</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/Battery-Box LV Flex Lite Operating Manual V1.0-61a03aa496a45.pdf" target="_blank" class="text-crema">OPERATION MANUAL</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/2022-2-8 BYD Battery-Box LV FLEX Service Guideline and Checklist-620b6db6193a9.pdf" target="_blank" class="text-crema">SERVICE GUIDELINE AND CHECKLIST</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/BYD Battery-Box LV Flex Lite Limited Warranty-Commercial-EN V1.1-6281f8ea4c1af.pdf" target="_blank" class="text-crema">LIMITED WARRANTY</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/Battery-Box LV Flex Lite FAQs.pdf" target="_blank" class="text-crema">FAQS</a>
                </li>
              </ul>
            </div>
          </div>
          
        <div class="col-lg-4 col-md-6">
            <div class="container-img  text-center">
      <img src="assets/img/HV-R.png" alt="services-img" style="width: 70%; height: auto;">
            </div>
            <div class="services-content text-center">
                <h3 class="texto-blanco fuente-cool-light">BYD HV Repowered</h3>
                <ul>
                  <li class="mt-2">
                    <a href="assets/pdf/HV Repowered Datasheet1.pdf" target="_blank" class="text-crema">DATASHEET</a>
                  </li>
                  <li class="mt-2">
                    <a href="assets/pdf/20201012_BYD_Battery-Box_HV_Compatible_Inverter_List_EU_V1.5_.pdf" target="_blank" class="text-crema">COMPATIBILITY LIST</a>
                  </li>
                  <li class="mt-2">
                    <a href="assets/pdf/QUICK INSTALLATION GUIDE HV Repowered.pdf" target="_blank" class="text-crema">QUICK START GUIDE</a>
                  </li>
                  <li class="mt-2">
                    <a href="assets/pdf/Battery-Box HV User Manual EN V5.0.pdf" target="_blank" class="text-crema">OPERATION MANUAL</a>
                  </li>
                  <li class="mt-2">
                    <a href="assets/pdf/20190612_Battery-Box_HV_-_Service_Guideline_and_Checklist_-_EN_V2.0(1).pdf" target="_blank" class="text-crema">SERVICE GUIDELINE AND CHECKLIST</a>
                  </li>
                  <li class="mt-2">
                    <a href="assets/pdf/BYD Battery-Box HV Repowered Warranty Letter_20210101.pdf" target="_blank" class="text-crema">LIMITED WARRANTY</a>
                  </li>
                  <li class="mt-2">
                    <a href="assets/pdf/Battery-Box HV Repowered FAQs.pdf" target="_blank" class="text-crema">FAQS</a>
                  </li>
                  </ul>         
            </div>
        </div>
      </div><br>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="container-img-resp-1 container-img-bmu text-center">
            <img src="assets/img/LV BMU.png" height="300" alt="services-img">
          </div>
          <div class="services-content text-center">
            <h3 class="texto-blanco fuente-cool-light">BYD LV BMU</h3>
            <ul class="texto-crema text-center">
              <li class="mt-2 ">
                <a href="assets/pdf/BYD Battery-Box Premium LV BMU- IP55 Instruction Manual.pdf" target="_blank" style="margin-right: 2em;" class="text-crema">OPERATION MANUAL</a>
              </li>
            </ul>
          </div><br>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="container-img-bmu text-center container-img-resp">
            <img src="assets/img/PV Panel.png" height="300px" alt="services-img">
          </div>
          <div class="services-content text-center">
            <h3 class="texto-blanco fuente-cool-light">BYD PV Panel</h3>
            <ul class="texto-crema text-center">
              <li class="mt-2">
                <a href="assets/pdf/MGK-36_-166-EN_(440W~455W).PDF" target="_blank" style="margin-right: 2em;" class="text-crema">OPERATION MANUAL</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            
          </div>
        </div>
      </div>
      
      
    </div>
</div>
</div>
<div class=" bg-0B1F0D  pt-100 pb-70" >
    <div class="container">
        <div class="section">
            <div class="battery-cea-home">
                <span style="color:#EFE6C0; opacity:0.5" >RAION</span><br><br>
            </div> 

    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
            <div class="container-img container-img-resp text-center">
              <img src="assets/img/Raion_J5.png" height="300px" alt="services-img">
            </div>
            <div class="services-content text-center">
              <h3 class="texto-blanco fuente-cool-light">RaiOn J5</h3>
              <ul class="texto-crema text-center">
                <li class="mt-2">
                  <a href="assets/pdf/RAION_J5_Datasheet.pdf" target="_blank" class="text-crema">DATASHEET</a>
                </li>
                <li class="mt-2"> 
                  <a href="assets/pdf/RAION_J5_Quick-Installation-Guide.pdf" target="_blank" class="text-crema">QUICK START GUIDE</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/RAIONJ5_Manual.pdf" target="_blank" class="text-crema">OPERATION MANUAL</a>
                </li>
              </ul>
            </div>
          </div>
          
        <div class="col-lg-4 col-md-6">
            <div class="container-img container-img-resp text-center">
              <img src="assets/img/Raion_M8.png" height="300px" alt="services-img">
            </div>
            <div class="services-content text-center">
              <h3 class="texto-blanco fuente-cool-light">RaiOn M8</h3>
              <ul class="texto-crema text-center">
                <li class="mt-2">
                  <a href="assets/pdf/RAION_M8_Datasheet.pdf" target="_blank" class="text-crema">DATASHEET</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/RAION_M8_Quick-Installation-Guide2.pdf" target="_blank" class="text-crema">QUICK START GUIDE</a>
                </li>
                <li class="mt-2">
                  <a href="assets/pdf/RAIONM8_Manual (1).pdf" target="_blank" class="text-crema">OPERATION MANUAL</a>
                </li>
              </ul>
            </div>
          </div>
          
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
          
          </div>
        </div>
      </div>
      
    </div>
</div>
</div>