<div class="main-banners main-banner-homes vertical-center mt-5 " >
  <div class="d-table hresp mt-5">
          <div class="container">
            <div class="row justify-content-center align-items-center mt-5" >
              <img class="img-what" src="assets/img/banner2.jpg" alt="about" style="width: 45em; height: auto;">
          </div>
          
      </div  >
  </div>
</div>
  <div class="main-banner-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="main-banner-text-ci">
                <h1 class="mobile-font-size pc-font-size degradeh1"  style="margin-top: 0.1em;margin-bottom: 0em; line-height:1.2em">BYD Battery-Box</h1>
                <p class="text-center degradeh1" style=" font-size: xx-large;">SOLUCIONES DE ALMACENAMIENTO DE ENERGÍA</p>
            </div>
        </div>
    </div>
</div>
  <div class=" ptb-100" style="background-color: #fffdfd;">
    <div class="container">
    <div class="">
  <div class="bg-fffdfd  pb-70" id="welcome-raion" >
    <div class="container">
        <div class="section">
            <div class="parrafos">
                <div class="col-lg-6 welcome-title1">
                    <h1 ><span class="borders-bottom-green" >BYD</span> BatteryBox</h1>
                </div>
                <div class="col-lg-6 mt-3 fuente-pop-reg welcome-title2 ">
                  <p>BYD ha desarrollado una línea de almacenamiento en baterías apta para cualquier aplicación. Mientras que las soluciones modulares LV y HV se adaptan a cualquier aplicación residencial, la batería comercial es adecuada para grandes implantaciones.</p>
              </div>
            </div>
            </div>
            <br>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-services-byd">
                        <div class="services-content ">
                            <h2>LV Flex Lite</h2>
                            <small class="borders-bottom-red">BYD BatteryBox 5 kWh de 320 kWh</small>
                        </div>
                        <div class="container-img text-center space-img" (mouseenter)="showText1()" (mouseleave)="hideText1()">
                            <img src="assets/img/LV FLEX LITE.png" style="width: auto; height: 70%; padding-top:4em"  alt="services-img">
                            <span class="text">
                                <div class="services-content">
                                    <h2>LV Flex Lite</h2>
                                    <small class="borders-bottom-red">BYD BatteryBox 5 kWh de 320 kWh</small>
                                </div>
                                <ul class="fuente-pop-reg">
                                    <li>&bull; Batería de iones de litio (LFP)</li>
                                    <li>&bull; 5 kWh por módulo</li>
                                    <li>&bull; De 1 a 64 módulos en paralelo</li>
                                    <li>&bull; (5 - 320kWh)</li>
                                    <li>&bull; Batería de bajo voltaje (48v)</li>
                                    <li>&bull; 10 años de garantía</li>
                                </ul>
                                      <div class="d-flex justify-content-center">
                                        <a class="btn btn-byd" style="margin-top: 12em;font-size:small" href="assets/pdf/230830_BYD_Battery-Box_LV_Flex_Lite_Datasheet_V1.3_EN-64ef10e1cdb7e (2).pdf" target="_blank" rel="noopener noreferrer">
                                          DESCARGAR FICHA TÉCNICA →
                                        </a>
                                    </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="single-services-byd">
                    <div class="services-content ">
                        <h2>Premium LVL</h2>
                        <small class="borders-bottom-red">BYD BatteryBox 15.4 de 983 kWh</small>
                      </div>
                    <div class="container-img text-center space-img" (mouseenter)="showText2()" (mouseleave)="hideText2()">
                      <img class="text-center" src="assets/img/LVL Frontal.png" height="80%"width="auto" style="padding-top: 3em;" alt="services-img">
                      <span class="text">
                        <div class="services-content ">
                            <h2>Premium LVL</h2>
                            <small class="borders-bottom-red">BYD BatteryBox 15.4 de 983 kWh</small>
                          </div>
                          <ul class="fuente-pop-reg">
                            <li>&bull; Batería de iones de litio (LFP)</li>
                            <li>&bull; 15.4 kWh por módulo</li>
                            <li>&bull; De 1 a 64 módulos en paralelo</li>
                            <li>&bull; (15.4 - 983kWh)</li>
                            <li>&bull; Batería de bajo voltaje (48v)</li>
                            <li>&bull; 10 año de garantía</li>
                        </ul>
                        <div class="d-flex justify-content-center">
                          <a class="btn btn-byd" style="margin-top: 12em;font-size:small" href="assets/pdf/230830_BYD_Battery-Box_Premium_LVL_(2021)_Datasheet_V2.2_ESP-64ef0ffddab7b (1).pdf" target="_blank" rel="noopener noreferrer">
                            DESCARGAR FICHA TÉCNICA →
                          </a>
                      </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="single-services-byd">
                    <div class="services-content">
                        <h2>FD-LV 5.0
                        </h2>
                        <small class="borders-bottom">BYD BatteryBox 5.1 de 11.5 kWh</small>
                      </div>
                    <div class="container-img text-center " (mouseenter)="showText3()" (mouseleave)="hideText3()">
                      <img class="size-img-resp sizeimg mt-5" src="assets/img/Captura_de_pantalla_2023-09-13_154447-removebg-preview.png"  alt="services-img">
                      <!-- <span class="text">
                        <div class="services-content">
                            <h2>FD-LV 5.0</h2>
                            <small class="borders-bottom">BYD BatteryBox 5.1 de 11.5 kWh</small>
                          </div>
                          <ul class="fuente-pop-reg">
                            <li>&bull; Batería de iones de litio (LFP)</li>
                            <li>&bull; 1.28 kWh por módulo</li>
                            <li>&bull; De 4 a 9 módulos en serie (5.12 - 11.52 kWh)</li>
                            <li>&bull; Batería de alto voltaje (48v)</li>
                            <li>&bull; 5 año de garantía</li>
                        </ul>
                        <div class="d-flex justify-content-center">
                          <a class="btn btn-byd" style="margin-top:11em; font-size:small" href="assets/pdf/HV-Repowered-Datasheet1.pdf" target="_blank" rel="noopener noreferrer">
                            DESCARGAR FICHA TÉCNICA →
                          </a>
                      </div>
                      </span> -->
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  </div>
    
          
</div>

</div>

<div class=" bg-fffdfd   ptb-100" id="welcome-raion">
    <div class="container">
    <div class="section">
        <div class=" mb-5">
          <h1 class="titulos-h1">BATTERY-BOX: VENTAJAS</h1>
            </div>         
            <br>   
            <div class="row justify-content-center equal-height">

</div>
</div>
</div>
</div>

<div class=" bg-fffdfd   pb-70" id="welcome-raion">
    <div class="container">
    <div class="section">       
            <br>   
            <div class="row justify-content-center equal-height">
            <div class="col-lg-4 col-md-6">
                <div class="">
                    <div class="services-content">
                        <h3>Aplicaciones <br> Off-Grid</h3> <br>                           
                    </div>
                    <div class="services-img">
                        <img src="assets/img/nue.jpeg" height="300" alt="services-img">
                    </div>
                    <div class="services-content">
                        <small>Las aplicaciones Off-Grid y el uso de energía de emergencia son algunos de los principales usos de las Battery-Box. La alta capacidad de descarga permite el funcionamiento desconectado de la red eléctrica. Ya sea que su proyecto suministre energía a una cabaña remota en las montañas o en una isla perdida o se utilice como respaldo para cargas sensibles, BYD tiene el almacenamiento adecuado para usted.</small>
                        
                    </div>
                </div>
            </div>
<div class="col-lg-4 col-md-6">
    <div class="">
        <div class="services-content">
            <h3 class="mb-4">Autoconsumo <br> <br></h3>                             
        </div>
        <div class="services-img">
            <img src="assets/img/WhatsApp Image 2023-11-24 at 09.50.46.jpeg" height="300" alt="services-img">
        </div>
        <div class="services-content">
            <small>El autoconsumo de energía renovable es el futuro. A medida que en todo el mundo disminuyen las subvenciones para la generación de energía renovable y aumentan los precios de la electricidad, se vuelve más económico consumir la energía que generas tú mismo.</small>
            
        </div>
    </div>
</div>
<div class="col-lg-4 col-md-6">
    <div class="">
        <div class="services-content">
            <h3>Aplicaciones <br>Industriales</h3> <br>                           
        </div>
        <div class="services-img">
            <img src="assets/img/ATGTNWNQCIOEULJCRC6SNBYP3I.webp" height="300" alt="services-img">
        </div>
        <div class="services-content">
            <small>Desde viviendas unifamiliares hasta aplicaciones comerciales. Si puedes diseñarlo, puedes utilizar la BatteryBox para construirlo. Como instalador, deseas trabajar con el mismo bloque de construcción común adaptándolo a las necesidades de tus clientes. Incluso puedes comenzar de manera más pequeña e ir agregando capacidad en cualquier momento de la vida del producto.</small>
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>
<div class="ptb-100 pb-70" style="background-color: #EFE6C0;">
    <div class="section">
<div class="battery  mb-5" >
    <h1 class="text-resp">¿QUÉ OFRECEMOS?</h1>
    </div>         
    </div>
    <br><br> 
    <div class="pb-70 pt-70" style="background-color: #EFE6C0;">
    <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="">
              <div class="services-img-container" style="width: 220px; height: 220px; border-radius: 50%; overflow: hidden; background-color: #94B976; display: flex; align-items: center; justify-content: center;">
                <div class="services-img-container" style="width: 130px; height: 130px; overflow: hidden; display: flex; align-items: center; justify-content: center;">                <img src="assets/img/icons8-recycle-100 (1).png" height="100" alt="services-img">
              </div>
              </div>
              <div class=""><br>
                <h2>Sostenibilidad </h2><br>
                <small class="fuente-pop-reg">Un paso importante para las energías renovables descentralizadas y efectivas</small>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class=""  >
              <div class="services-img-container" style="width: 220px; height: 220px; border-radius: 50%; overflow: hidden; background-color: #94B976; display: flex; align-items: center; justify-content: center;">
                <div class="services-img-container" style="width: 130px; height: 130px; overflow: hidden; display: flex; align-items: center; justify-content: center;">

                <img src="assets/img/icons8-money-100.png" height="90px" alt="services-img">
                </div>
              </div>
              <div class=""><br>
                <h2>Económico</h2><br>
                <small class="fuente-pop-reg">Ahorra dinero y aumenta la autosuficiencia aumentando el autoconsumo</small>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="">
              <div class="services-img-container" style="width: 220px; height: 220px; border-radius: 50%; overflow: hidden; background-color: #94B976; display: flex; align-items: center; justify-content: center;">
                <div class="services-img-container" style="width: 130px; height: 130px; overflow: hidden; display: flex; align-items: center; justify-content: center;">

                <img src="assets/img/icons8-gps-100.png"  height="100px" alt="services-img">
              </div>
              </div>
              <div class=""><br>
                <h2>Local</h2><br>
                <small class="fuente-pop-reg">Disfrute del soporte de nuestro equipo local</small>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="">
              <div class="services-img-container" style="width: 220px; height: 220px; border-radius: 50%; overflow: hidden; background-color: #94B976; display: flex; align-items: center; justify-content: center;">
                <div class="services-img-container" style="width: 130px; height: 130px; overflow: hidden;  display: flex; align-items: center; justify-content: center;">
                  <img src="assets/img/icons8-lock-100.png" height="100px" alt="services-img" style="border-radius: 50%; object-fit: cover;">
              </div>
              </div>
              <div class=""><br>
                <h2>Seguridad</h2><br>
                <small class="fuente-pop-reg">Las robustas baterías de ion litio y hierro cumplen con los requisitos más altos de seguridad y calidad</small>
              </div>
            </div>
          </div>
          
        </div>
      </div>
</div>
</div>
