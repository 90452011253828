<footer class="">
    <div class="container">
        <!-- <div class="row">
          <div class="col-md-6 mb-5">
            <img src="/assets/img/" height="100" alt="Logo">
          </div>
        </div>

          <div class="row ">
            <div class="row col-md-5 borders-top me-3">
              <div class="col-5">
              <p class=" ">Contact Us</p>
              <p class="mt-3 fuente-pop" style="color:#94b976;font-size:small">GENERAL INQUIERES</p>
              <p style="font-size:small" class="texto-negro">+254 712 988 211</p>
              <p style="font-size:small" class="texto-negro ">6th floor Morningside Office Park, Kilimani, Nairobi, Kenya</p>
              <p style="font-size:small" class="texto-negro">sales@collective-energy.africa</p>
              <p style="font-size:small" class="texto-negro">info@collective-energy.africa</p>
            </div>
              <div class="col-5">
              <p class="fuente-pop" style="color:#94b976; font-size:small; margin-top: 2.4rem">TECHINICAL SUPPORT</p>
              <p style="font-size:small" class="texto-negro">+254 207 640 823</p>
              <p style="font-size:small" class="texto-negro">service@collective-energy.africa</p>
            </div>
            </div>
            <div class="col-md-2 borders-top me-3" >
              <p class="">Follow us at</p>
              <p style="font-size:small"class="texto-negro mt-3">Linkedin</p>
              <p style="font-size:small"class="texto-negro">Instagram</p>
              <p style="font-size:small"class="texto-negro">Facebook</p>
            </div>
            <div class="row col-md-5 borders-top">
              <div class="col-md-6">
              <p class="">Navigate</p>
              <p style="font-size:small" class="">&nbsp;</p>
              <p style="font-size:small" class="texto-negro"><a routerLink="/Home">Home</a></p>
              <p style="font-size:small" class="texto-negro"><a routerLink="/raion?section=raion">Our products</a></p>
              <p style="font-size:small"class="texto-negro"><a routerLink="/about-us?section=about-us">About us</a></p>
              <p style="font-size:small" class="texto-negro"><a routerLink="/byd-betterybox?section=byd-betterybox">BYD BatteryBox</a></p>
              <p style="font-size:small" class="texto-negro"><a routerLink="/Contact?section=Contact">Contact us</a></p>
              <p style="font-size:small" class="texto-negro"><a routerLink="/Where-to-buy?section=Where-to-buy">Where to Buy</a></p>
            </div>
            <div class="col-md-6">
              <p class="">&nbsp;</p>
              <p class="">&nbsp;</p>
              <p style="font-size:small" class="texto-negro"><a href="https://support.collective-energy.africa/" target="_blank">Online service center</a></p>
              <p style="font-size:small" class="texto-negro"><a routerLink="/Downloads?section=Downloads">Downloads</a></p>
            </div>
          </div>

          </div> -->
          <!-- <div class="col-md-12 text-left" style="font-size: small;">
            <ul style="font-size: medium;">
                <li class="col-md-2 borders-top">Contact Us</li>
                <li class="col-md-2 borders-top trasp"> s</li>
                <li class="col-md-2 borders-top">Follow us at</li>
                <li class="col-md-2 borders-top">Navigate</li>
                <li class="col-md-2 borders-top trasp">s</li>
            </ul>
            <ul>
                <li class="col-md-2" style="color:#3A9B43">GENERAL INQUIERES</li>
                <li class="col-md-2"style="color:#3A9B43">TECHINICAL SUPPORT</li>
                <li class="col-md-2">Linkedin</li>
                <a routerLink="/home-seven"><li class="col-md-2" style="color:black">Home </li></a>
                <li class="col-md-2">Online service center</li>            
            </ul>
            <ul>
                <li class="col-md-2">+254 712 988 211</li>
                <li class="col-md-2">+254 207 640 823</li>
                <li class="col-md-2">Instagram</li>
                <a routerLink="/home-two"><li class="col-md-2" style="color:black">Our products</li></a>
                <li class="col-md-2">Downloads</li>            
            </ul>
            <ul>
                <li class="col-md-2">6th floor Morningside Office Park, Kilimani, Nairobi, Kenya</li>
                <li class="col-md-2">service@collective-energy.africa</li>
                <li class="col-md-2">Facebook</li>
                <a routerLink="/home-three"><li class="col-md-2" style="color:black">About us</li></a>
                <li class="col-md-2"></li>            
            </ul>
            <ul>
                <li class="col-md-2">sales@collective-energy.africa</li>
                <li class="col-md-2"></li>
                <li class="col-md-2"></li>
                <a routerLink="/home-six"><li class="col-md-2" style="color:black">Contact us</li></a>
                <li class="col-md-2"></li>            
            </ul>
            <ul>
                <li class="col-md-2">info@collective-energy.africa</li>
                <li class="col-md-2"></li>
                <li class="col-md-2"></li>
                <a routerLink="/home-nine"><li class="col-md-2" style="color:black">Where to Buy</li></a>
                <li class="col-md-2"></li>            
            </ul>
          </div> -->
      </div>
</footer>

