    <div class="main-banner-home">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="main-banner-text-ci">
                    <h1 class="mobile-font-size pc-font-size texto-blanco"  style="margin-top: 0.1em;margin-bottom: 0em;">C&I Solutions</h1>
                    <p class="text-center texto-blanco" style=" font-size: xx-large;">OFRECEMOS PRODUCTOS INDUSTRIALES Y COMERCIALES A SU MEDIDA</p>
                </div>
            </div>
        </div>
    </div>
<div class="bg-fffdfd pt-70 pb-70" id="welcome-raion">
    <div class="container resp-mt-2">
        <div class="section">
            <div class="parrafos">
                <div class="col-lg-6 welcome-title1">
                    <h1><span class="borders-bottom-green">C&I</span></h1>
                </div>
                <div class="col-lg-6 mt-3 fuente-pop-reg welcome-title2">
                    <p>Dos diferentes productos dependiendo de tus necesidades. Las soluciones C&I se desarrollan de la mano de ByD.</p>
                </div>
            </div>
            <br>
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-6">
                    <div class="single-services-byd space-img">
                        <div class="services-content">
                            <h3 class="fuente-cool-light">Chess Pro</h3>
                            <small class="borders-bottom fuente-pop-reg">200kW/215kWh (100W carga)</small>
                        </div>
                        <div class="container-img text-center">
                            <img src="assets/img/ChessPro.jpg" class="" alt="services-img" height="max-height: 400px; max-width: 100%;">
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-6">
                    <div class="single-services-byd">
                        <div class="services-content">
                            <h3 class="fuente-cool-light">MC Cube</h3>
                            <small class="borders-bottom fuente-pop-reg">200kW/447kWh</small>
                        </div>
                        <div class="container-img text-center">
                            <img src="assets/img/MCCube.jpg" class="" alt="services-img" height="max-height: 400px; max-width: 100%;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="p100-70 pt-100 main-banner-home-ci" style="background-color: #001a03;">
    <div class="container">
        <div class="section ">
            <div class="">
                <h1 class="mobile-font-size pc-font-size texto-blanco" style="margin-top:1em;">AltaFox & EFT</h1>
                <h1 class=" texto-blanco">KNOW-HOW</h1>
            </div>
            <br><br>
            <div style="text-align: center; width: 100%;">
                <small style="display: inline-block; width: 100%; max-width: 600px; text-align: center; line-height: 1.5; font-size: 1rem;color:#ffffff" class="fuente-pop-reg">
                    Diseñar la mejor solución de control e instrumentación (C&I) requiere seleccionar cuidadosamente la combinación correcta de componentes. Una vez configurada, la solución completa se entrega para su instalación. Luego, el sistema se pone en funcionamiento para garantizar que entregue las funciones necesarias de manera efectiva. Este enfoque garantiza un sistema óptimo y seguro, listo para funcionar y beneficiar a todos.

                </small>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="section ">
            <div class="d-flex justify-content-center">
                <a href="https://eft-systems.de/de/" target="_blank">
                    <button type="submit" style="height: 40px; width: 230px;" class="btn boton boton-ci fuente-pop-reg">VISITAR EFT</button>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid pb-5" style="background-color: #001a03;">
    <div class="container " style="color:#ffffff; border-bottom:#ffffff">   
        <div class="row">
            <div class="col-md-12 ico-pad">
                <div class="card-ci">
                    <div class="card-body-ci" style="background-color: #001a03; text-align: center;">
                        <div class="row justify-content-center">
                            <div class="col-6 col-md-2 ico-pad">
                                <img src="assets/img/lamp.png" alt="Imagen 1" width="90"><br>
                                <small>Energía inteligente</small>
                            </div>
                            <div class="col-6 col-md-2 ico-pad">
                                <img src="assets/img/icons8-check-96.png" alt="Imagen 2" width="100"><br>
                                <small>Instalaciones sencillas</small>
                            </div>
                            <div class="col-6 col-md-2 ico-pad">
                                <img src="assets/img/icons8-hand-shake-64.png" alt="Imagen 3" width="100"><br>
                                <small>Garantía de 10 años</small>
                            </div>
                            <div class="col-6 col-md-2 ico-pad">
                                <img src="assets/img/icons8-battery-96 (2).png" alt="Imagen 4" width="100"><br>
                                <small>Baterías seguras</small>
                            </div>
                            <div class="col-6 col-md-2 ico-pad">
                                <img src="assets/img/icons8-person-64.png" alt="Imagen 4" width="90"><br>
                                <small>Máximo rendimiento técnico</small>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-6 col-md-2 ico-pad">
                                <img src="assets/img/icons8-sun-100.png" alt="Imagen 3" width="100"><br>
                                <small>Autoconsumo renovable </small>
                            </div>
                            <div class="col-6 col-md-2 ico-pad">
                                <img src="assets/img/icons8-cloud-100.png" alt="Imagen 4" width="100"><br>
                                <small>Tiempo de uso </small>
                            </div>
                            <div class="col-6 col-md-2 ico-pad">
                                <img src="assets/img/icons8-check-ceoeste.png" alt="Imagen 3" width="100"><br>
                                <small>Respaldo energético</small>
                            </div>
                            <div class="col-6 col-md-2 ico-pad">
                                <img src="assets/img/icons8-truck-64.png" alt="Imagen 3" width="100"><br>
                                <small>Optimización de la carga de VE</small>
                            </div>
                            <div class="col-6 col-md-2 ico-pad">
                                <img src="assets/img/icons8-money-box-100.png" alt="Imagen 4" width="100"><br>
                                <small>Máximo ahorro</small>
                            </div>
                            <div class="col-6 col-md-2 ico-pad">
                                <img src="assets/img/icons8-law-64.png" alt="Imagen 4" width="100"><br>
                                <small>Regulación de frecuencia </small>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
                    </div>
                    </div>
</div>

<div class="container-fluid" style="background-color: #fffdfd; display: flex; align-items: center; justify-content: center; height: 90hv;">
    <h1 class="mobile-font-size pc-font-size-mt titulos-h1 mb-5" style="margin-top: 1.5em; text-align: center;">SERVICIOS O&M</h1>
</div>
        <div class="container-fluid pb-5" style="background-color: #fffdfd; padding-left:3em;padding-right:3em" >
            <div class="container">
                <div class="row">
                    <div class="col-md-4 mb-4 ml-5 ">
                        <div class="">
                            <div class="icon-border text-center" style="vertical-align: top;">
                                <img src="assets/img/icons8-classroom-100.png" height="140" alt="services-img">
                            </div>
                            <div class="text-right"><br>
                                <h3 class="mb-3">EDUCACIÓN </h3>
                                <small class="fuente-pop-reg">· Capacitaciones de instaladores en el sitio
                                    <br> · E-learning <br> · Materiales del cliente final</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-4 ml-5">
                        <div class="">
                            <div class="icon-border text-center" style="vertical-align: top;">
                                <img src="assets/img/icons8-palm-up-hand-100.png" height="140" alt="services-img">
                            </div>
                            <div class="text-left"><br>
                                <h3 class="mb-3">SOPORTE </h3>
                                <small class="fuente-pop-reg">· Seguimiento a medida
                                    <br> · Portal de servicios <br> · Soporte remoto</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-4 mr-5">
                        <div class="">
                            <div class="icon-border text-center" style="vertical-align: top;">
                                <img src="assets/img/icons8-tools-100.png"  height="140" alt="services-img">
                            </div>
                            <div class=""><br>
                                <h3 class="mb-3">REPARACIONES</h3>
                                <small class="fuente-pop-reg">· Gestión de Spare Parts <br> · Reparaciones <br> · Soporte in-site (OPCIONAL)</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

