
  <div class="main-banner-home">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="main-banner-text-ci">
                <h1 class="mobile-font-size pc-font-size texto-blanco"  style="margin-top: 0.1em;margin-bottom: 0em; line-height:1.2em">AltaFox con ByD</h1>
                <p class="text-center texto-blanco" style=" font-size: xx-large;">The energy for a better tomorrow</p>
            </div>
        </div>
    </div>
</div>
  <div class="container-fluid main-banner-imagen ptb-100">
    <div class="container">
      <div class="">
        <div class="container">
          <div class="card-cea" style="opacity: 0.94;">
            <div class="card-body" style="padding: 1rem !important;">
              <div class="row align-items-center ">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content">
                    <div class="section-title">
                      <h1 style="text-align: center; font-size: 50px">
                        NOSOTROS SOMOS <br>
                        <div style="text-align: center;">
                            <img src="assets/img/LOGO.png" alt="" height="80px">
                        </div>
                    </h1><br>
                        <p class="fuente-pop-reg">En Altafox, nos dedicamos a la distribución de productos de BYD Battery Box en América Latina. Nuestra misión es facilitar el acceso a soluciones de almacenamiento de energía solar confiables y eficientes en toda la región. <br><br> Además, nos enorgullece ofrecer capacitación especializada a nuestros clientes. <br><br> Nuestro compromiso no termina con la venta, nuestro equipo de servicio postventa está siempre listo para ofrecer un excelente soporte y asistencia técnica, garantizando que nuestros clientes disfruten de una experiencia sin problemas y una energía sostenible a largo plazo.
                        </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-center">
                  <div class="about-video ">
                    <img class="img-respons" src="assets/img/Captura de pantalla 2023-09-13 160557.png"  alt="about">
                    <div class="video-btn">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-banner-home ptb-100 d-flex align-items-center justify-content-center">
    <div class="main-banner-text-ci text-center">
        <h1 class="mobile-font-size pc-font-size texto-blanco" style="line-height: 1.5em;">PARTNERS DE ByD</h1>
    </div>
</div>

  <div class="main-banner-home ptb-100" style="height: auto;">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="card-byd" style="opacity: 0.9;">
            <div class="card-body" style="padding: 40px;">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content text-center">
                    <div class="section-title">
                      <div class="about-video">
                        <img src="assets/img/Captura de pantalla 2023-09-14 112425.png" alt="about">
                        <div class="video-btn">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-left">
                  
                  <p>BYD (Bluid Your Dreams) es el mayor fabricante de baterías solares del mundo, así como un proveedor líder de vehículos eléctricos. A nivel mundial, BYD es una de las empresas más exitosas, innovadoras y de más rápido crecimiento.
                  </p> <br>
                    <p> BYD se fundó en febrero de 1995 en Shenzhen, China, con sólo 20 trabajadores. Hoy en día, BYD emplea a más de 220.000 personas en todo el mundo, incluidas ubicaciones de producción y ventas en Europa, América, Japón, India y muchos más.
                    </p> <br>
                    <p> BYD es la primera empresa a gran escala involucrada en toda la cadena energética, desde la generación hasta el almacenamiento y el consumo, para lograr una industria energética sostenible.
                    </p> <br>
                    <h1 class="mt-1"><a href="https://www.bydbatterybox.com/" target="_blank"style="text-align: center; font-size: 20px;margin-left:0.8em; color:#94b976">CONOCER MÁS DE BYD</a></h1><br>

                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="container-fluid bg-fffdfd ptb-100">
    <div class="container">

          <div class="section">
            <div class="">
              <h1 class="titulos-h1" style="line-height: 1.2em;">UN ECOSISTEMA CON  <br> CERO EMISIONES
                </h1>
              <span></span>
            </div>
            <div class="row mt-5">
              <div class="text-center">
                <small style="font-size:larger" class="mb-5">BYD es el mayor fabricante de vehículos eléctricos del mundo. 
                  <br>
                  Desde carretillas elevadoras eléctricas hasta autobuses de dos pisos en Londres o el nuevo Skyrail: BYD <br> electrifica todos los medios de transporte para crear un ecosistema libre de emisiones.

                </small>
              </div>
            </div>
          </div>
          <div class="bg-fffdfd ptb-100">

      <div class="row align-items-stretch">
        
              <div class="col-lg-3 col-md-6 ">
                <div class="mt-5">
                  <div class="services-content">
                    <h3><span class="borders-top">Generación </span>de  <br> energía solar</h3> <br>
                  </div>
                  <div class="services-img mt-5" >
                    <img src="assets/img/64220d5197a66.jpeg" height="250"width="350" alt="services-img">
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="mt-5">
                    <div class="services-content">
                        <h3 ><span class="borders-top">Iluminación </span> electrificada en el <br> transporte</h3> <br>                            
                    </div>
                    <div class="services-img mt-3">
                        <img src="assets/img/8aa18c20d721aa38776ede2a8c8d4975_01.jpg" height="250"width="350" alt="services-img">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="mt-5">
                    <div class="services-content">
                        <h3><span class="borders-top">Almacenamiento</span> <br> energético</h3> <br>                           
                    </div>
                    <div class="services-img mt-5">
                        <img src="assets/img/hvm-83.jpg" height="250" width="350" alt="services-img">
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="mt-5">
                    <div class="services-content">
                        <h3><span class="borders-top">Led</span> <br> <br></h3> <br>                           
                    </div>
                    <div class="services-img mt-5">
                        <img src="assets/img/12-Meter-bus-Madrid.jpg" height="250" width="350" alt="services-img">
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        </div>