<div class="main-banner-home">
  <div class="d-table">
      <div class="d-table-cell">
          <div class="main-banner-text-ci">
              <h1 class="mobile-font-size pc-font-size texto-blanco"  style="margin-top: 0.1em;margin-bottom: 0em; line-height:1.2em">Encuentre nuestros distribuidores!</h1>
              <p class="text-center texto-blanco" style=" font-size: xx-large;">Más información</p>
          </div>
      </div>
  </div>
</div>
<div class=" bg-fffdfd  pt-100 pb-70" id="welcome-raion">
    <div class="container">
        <div class="section">
    <br>
    <!-- <div class="row justify-content-center">
        <div class="col-lg-12 col-md-6 ">
          <div id="map" class="map" style="height: 500px;"></div>
          
          <script
      src="https://maps.googleapis.com/maps/api/js?key=AIzaSyClpMFjMX_UTHmMTepnY0V_oI2_xjhQX-s&callback=initMap&v=weekly"
      defer>
    </script>
        </div>
      </div> -->
        <div class="col-lg-6 welcome-title1" >
            <h1>¿Quiere ser nuestro distribuidor?</h1>
        </div>
        <div class="col-lg-6 welcome-title2">
        </div>
    </div>
    <form [formGroup]="contactForm" (ngSubmit)="submit()" *ngIf="formularioEnviado == false">
    <div class="container">
      <div class="row">      
        <div class="col-md-6 col-sm-12">
            <div class="form-group">
              <label for="first-name"></label>
              <input minlength="3" maxlength="50" type="text" formControlName="first_name" placeholder="Nombre" required>
              <div *ngIf="contactForm.controls['first_name'].invalid && (contactForm.controls['first_name'].dirty || contactForm.controls['first_name'].touched || isSubmitted)" class="alert">
                <small class="text-danger" *ngIf="contactForm.controls['first_name'].errors.required">
                  Introduzca su nombre
                </small>
            </div>  
            </div>
            <div class="form-group">
              <label for="surname"></label>
              <input minlength="3" maxlength="50" type="text" formControlName="surname" placeholder="Apellido"required>
              <div *ngIf="contactForm.controls['surname'].invalid && (contactForm.controls['surname'].dirty || contactForm.controls['surname'].touched || isSubmitted)" class="alert">
                <small class="text-danger" *ngIf="contactForm.controls['surname'].errors.required">
                  Introduzca sus apellidos
                </small>
            </div> 
            </div>
            <div class="form-group">
              <label for="company-name"></label>
              <input type="text" formControlName="company_name" placeholder="Compañía" required>
              <div *ngIf="contactForm.controls['company_name'].invalid && (contactForm.controls['company_name'].dirty || contactForm.controls['company_name'].touched || isSubmitted)" class="alert">
                <small class="text-danger" *ngIf="contactForm.controls['company_name'].errors.required">
                  Introduzca el nombre de su empresa
                </small>
            </div> 
            </div>
            <div class="form-group">
              <label for="email"></label>
              <input type="email" formControlName="email" placeholder="Email"required>
              <div *ngIf="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched || isSubmitted)" class="alert">
                <small class="text-danger" *ngIf="contactForm.controls['email'].errors.required">
                  Introduzca su dirección de correo electrónico
                </small>
                <small class="text-danger" *ngIf="contactForm.controls['email'].errors.email">
                  Introduzca una dirección de correo electrónico válida
                    </small>
            </div> 
            </div>
           
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="message"></label>
            <textarea rows="12" formControlName="message" placeholder="Mensaje"></textarea>
            <div *ngIf="contactForm.controls['message'].invalid && (contactForm.controls['message'].dirty || contactForm.controls['message'].touched || isSubmitted)" class="alert">
              <small class="text-danger" *ngIf="contactForm.controls['message'].errors.required">
                Escriba su mensaje
              </small>
          </div> 
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="form-group mt-5">
              <input type="submit" value="Enviar →" class="btn boton" style="padding-left: 2rem; padding-right: 2rem; padding-top: 3px; padding-bottom: 3px;">
            </div>
          </div>
        </div>
      </div>
      </div>
    </form>
    <div class="row" *ngIf="formularioEnviado == true">
      <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="form-group mb-3 text-center">
              <p>Su mensaje se ha enviado correctamente.</p>
              </div>
      </div>
  </div>
    </div>
</div>