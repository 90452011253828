<!-- <div class="main-banner-home pbt-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text-ci" >
                    <h1 class="pc-font-size mobile-font-size degradeh1" style=" line-height: 1.5em;">Estamos aquí para ayudar</h1>
                    <h1 class="pc-font-size mobile-font-size degradeh1" style="margin-top:0em; line-height: 1.5em;">y asesorar</h1>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-banner-contact pt-70"style="height:18em;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text" >
                    <div class="row mt-3">
                    <div class="col-lg-7 col-md-6 justify-context-right">
                        <p style="color:#EFE6C0;" class="fuente-pop-reg">Contact</p>
                        <button type="button" class="btn btn-second " style="padding-left: 2em;padding-right:2em">
                            <a href="mailto:sales@collective-energy.africa">sales@collective-energy.africa</a>
                        </button>
                        <p style="color:#94b976; font-size:large" (click)="copyText('+254 712 988 211')">+254 712 988 211</p><br>

                    </div>
                    <div class="col-lg-3 col-md-6">
                        <p style="color:#EFE6C0;" class="fuente-pop-reg">Technical support</p>
                        <button type="button" class="btn btn-second"style="padding-left: 2em;padding-right:2em">
                            <a href="mailto:service@collective-energy.africa">
                                service@collective-energy.africa
                            </a></button>
                        <p style="color:#94b976; font-size:large" (click)="copyText('+254 207 640 823')">+254 207 640 823</p><br>

                      </div>
                      
                </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="container-fluid main-banner-cont" style="padding-top: 7rem;">
    <div class="">
        <div class="">
            <div class="container">
                <div >
                    <div class="" style="padding-top: 20px; padding-bottom: 20px;">
                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12">
                                <div class="card-contacts" style="opacity: 0.9;">

                                <form [formGroup]="contactForm" (ngSubmit)="submit()" *ngIf="formularioEnviado == false" class="container" style="padding:2em;">
                                    <h1 class="form-title ">¿Podemos ayudarte?</h1>
                                    <div class="row form-group">
                                        <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                            <label for="first-name"></label>
                                            <input minlength="3" maxlength="50" type="text" formControlName="first_name" placeholder="Nombre" required>
                                            <div *ngIf="contactForm.controls['first_name'].invalid && (contactForm.controls['first_name'].dirty || contactForm.controls['first_name'].touched || isSubmitted)" class="alert">
                                              <small class="text-danger" *ngIf="contactForm.controls['first_name'].errors.required">
                                                Introduzca su nombre
                                              </small>
                                          </div>  
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                            <label for="surname"></label>
                                            <input minlength="3" maxlength="50" type="text" formControlName="surname" placeholder="Apellido" required>
                                            <div *ngIf="contactForm.controls['surname'].invalid && (contactForm.controls['surname'].dirty || contactForm.controls['surname'].touched || isSubmitted)" class="alert">
                                              <small class="text-danger" *ngIf="contactForm.controls['surname'].errors.required">
                                                Introduzca sus apellidos
                                              </small>
                                          </div> 
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                            <label for="company-name"></label>
                                            <input type="text" formControlName="company_name" placeholder="Compañía" required>
                                            <div *ngIf="contactForm.controls['company_name'].invalid && (contactForm.controls['company_name'].dirty || contactForm.controls['company_name'].touched || isSubmitted)" class="alert">
                                              <small class="text-danger" *ngIf="contactForm.controls['company_name'].errors.required">
                                                Introduzca el nombre de su empresa
                                              </small>
                                          </div> 
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                            <label for="email"></label>
                                            <input type="email" formControlName="email" placeholder="Email" required>
                                            <div *ngIf="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched || isSubmitted)" class="alert">
                                              <small class="text-danger" *ngIf="contactForm.controls['email'].errors.required">
                                                Introduzca su dirección de correo electrónico
                                              </small>
                                              <small class="text-danger" *ngIf="contactForm.controls['email'].errors.email">
                                                Introduzca una dirección de correo electrónico válida
                                                  </small>
                                          </div> 
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <label for="message"></label>
                                            <textarea rows="12" formControlName="message" placeholder="Mensaje"></textarea>
                                            <div *ngIf="contactForm.controls['message'].invalid && (contactForm.controls['message'].dirty || contactForm.controls['message'].touched || isSubmitted)" class="alert">
                                              <small class="text-danger" *ngIf="contactForm.controls['message'].errors.required">
                                                Escriba su mensaje
                                              </small>
                                          </div> 
                                        </div>
                                    </div>

                                    <div class="row justify-content-end "> 
                                        <div class="col-lg-12 col-md-12 col-sm-12 text-right mt-2"> 
                                            <button type="submit" style="padding-left: 2rem; padding-right: 2rem; padding-top: 3px; padding-bottom: 3px;" class="btn boton btn-right">Enviar →</button>
                                        </div>
                                    </div>
                                </form>
                                <div class="row" *ngIf="formularioEnviado == true">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group mb-3 mt-3 text-center ">
                                            <p>Su mensaje se ha enviado correctamente.</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                    
                </div>
            </div>
        </div>

